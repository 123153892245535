import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonInduxtry } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'button-induxtry',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, AfterViewInit {
  @Input() buttonProperties: ButtonInduxtry;

  @Output() action: EventEmitter<any> = new EventEmitter();
  loaded = false;

  ngOnInit() {
      if (this.buttonProperties && this.buttonProperties?.customStyle === undefined || this.buttonProperties?.customStyle === null) {
        this.buttonProperties.customStyle = 'primary';
      }
    
   

    if(this.buttonProperties && this.buttonProperties?.showLabel === undefined || this.buttonProperties?.showLabel === null ) {
      this.buttonProperties.showLabel = true;
    }
    if(this.buttonProperties && this.buttonProperties?.dropDown === undefined || this.buttonProperties?.dropDown === null ) {
      this.buttonProperties.dropDown = false;
    }
  }

  onButtonClicked(params: any, key?: string) {
    this.action.emit([params, key]);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true
    })

  }

}
