export const environment = {
  production: false,
  apiUrl:'https://qa-test-rolling-ob-twin.enacloud.me',
  // apiUrl:'https://63a59f6af8f3f6d4abfb383d.mockapi.io/api-portfolio',

  apiUrlBuffer: '',

  client: '',
  redirectUri: 'http://localhost:4200',
  authority: '',
};
